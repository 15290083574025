<template>
    <div class="container">

        <loading-state
            v-if="loading">
        </loading-state>

        <div class="title">
            <h1>Problems <button v-if="!hasUndefinedBoardType()" class="btn btn-success" @click="$bvModal.show('add-problem-modal')">Add Problem</button></h1>
        </div>

        <div class="error-container" v-if="hasUndefinedBoardType()">
            <div class="card card-body pb-2 mb-3 text-center" v-if="!savingBoard">
                <div >
                    <h3>Warning: Unknown board size</h3>
                    <p class="mb-0">This device has not been assigned a board size.</p>
                    <p class="mb-0">Please select from the following available sizes before continuing.</p>
                    <hr>
                    <div class="row">
                        <div class="col-4 offset-2">
                            <select name="selectBoard" id="boards" class="form-control" v-model="selectedBoardType">
                                <option value="default" disabled hidden>Select a Board Size</option>
                                <option v-for="(board, index) in boards" :key="index" :value="board.id">{{board.title}}</option>
                            </select>
                        </div>
                        <div class="col-4">
                            <button class="btn btn-success d-block" @click="updateBoardType()">
                                Update Board Size
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-body pb-2 mb-3 text-center align-items-center" v-else>
                <animated-loading></animated-loading>
                <h4 class="mt-3">Saving</h4>
            </div>
        </div>

        <div class="problem-wrapper" v-else>
            <div class="card px-2" v-if="problems.length === 0">
                <empty-state
                    title="No problems have been added"
                    subtitle="Click the button above to get started">
                </empty-state>
            </div>

            <template v-else>
                <div class="card card-body pb-2 mb-3" v-for="(problem, index) in problems" :key="index">
                    <dl class="mb-0">
                        <dd>{{problem.title}}</dd><dt v-for="(symptom, symptomIndex) in problem.symptoms" :key="symptomIndex+Math.random()">{{symptom.title}}</dt>
                    </dl>
                </div>
            </template>
        </div>


        <!-- Footer -->
        <fixed-footer
            :class="{'active': footerActive}"
            :nextLabel="`Next`"
            :nextRoute="`revisit.repair-parts`"
            :prevRoute="`job`">
        </fixed-footer>

        <add-problem-modal></add-problem-modal>
        <problem-symptom-modal></problem-symptom-modal>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "DeviceIssues",

    data() {
        return {
            loading: true,
            savingBoard: false,
            selectedBoardType: 'default',
            problems: [],
            boards: []
        }
    },

    mounted() {
        this.reloadProblems();
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId === 'problem-symptom-modal') {
                this.reloadProblems();
            }
        })
        this.loadBoardTypes();
        localStorage.setItem('boardType', this.device.board);
    },

    computed: {
        ...mapGetters([
            "device",
            "job"
        ]),

        /** Check if footer should be active */
        footerActive() {
            return this.problems.length > 0 && !this.hasUndefinedBoardType();
        }
    },

    methods: {
        reloadProblems() {
            this.loading = true;
            this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}`)
                .then(res => {
                    this.loading = false;
                    this.problems = res.data.data.problems;

                    this.problemStillHasSymptoms();
                })
        },


        loadProblems() {
            this.loading = true;
            this.problems = [];
            this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}`)
                .then(res => {
                    this.loading = false;
                    this.existingProblems = res.data.data.problems;
                    const allProblems = res.data.data.device.problems_available;

                    allProblems.forEach(problem => {
                        problem.existing = false;

                        res.data.data.problems.forEach(existingProblem => {
                            if (problem.id === existingProblem.id) {
                                problem.existing = true;
                            }
                        });

                        this.problems.push(problem);
                    });
                });
        },

        loadBoardTypes() {
            this.loading = true;
            this.boards = [];
            this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}`)
                .then(res => {
                    let device = res.data.data.device.id;
                    localStorage.setItem('deviceId', device);
                });

            this.$http.get(`/api/v4/orders/devices/${this.device.id}/available-board-types`)
                .then(response => {
                    const availableBoardTypes = response.data.data;
                    availableBoardTypes.forEach(boardType => {
                        this.boards.push(boardType);
                    });
                });
        },

        problemStillHasSymptoms() {
            this.problems.forEach(problem => {
                if (problem.symptoms.length === 0) {
                    this.$http.delete(`/api/v4/warranty/booking/${localStorage.revisitBookingId}/problem/${problem.id}`);
                }
            });
        },

        hasUndefinedBoardType() {
            let board = localStorage.getItem('boardType');
            return board === "undefined" || board === "Undefined";
        },

        updateBoardType() {
            this.savingBoard = true;
            this.$http.put(`/api/v4/orders/devices/${this.device.id}/update-board-type`, {
                board_type_id: this.selectedBoardType
            })
                .then(response => {
                    if(response.data.status_code === 200) {
                        this.savingBoard = false;
                        this.$forceUpdate();
                        localStorage.removeItem('boardType');
                        localStorage.removeItem('deviceId');
                    }
                }).catch(error => {
                alert(JSON.stringify(error.response.data.messages));
                this.savingBoard = false;
            });

        }
    }
}
</script>

<style scoped>

</style>
